::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  scrollbar-width: 8px !important;
  margin: 5px;
  padding: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px #0000004d;
  border-radius: 10px;
  scrollbar-width: 8px !important;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px #0000004d;
  scrollbar-width: 8px !important;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: transparent;
  line-height: 1.15;
  scrollbar-width: 8px !important;
}

body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
  padding-right: 0px !important;
  background: #fff !important;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scrollbar-width: 8px !important;
}

*,
:after,
:before {
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  background-size: cover;
  position: relative;
  scrollbar-width: 8px !important;
}

#root {
  min-height: 100%;
  position: relative;
}

#main {
  padding-bottom: 60px; /* Height of the footer */
}

#footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Height of the footer */
}

/* App Bar Css */
.app-bar {
  background-color: #fff !important;
  color: #000 !important;
}

.app-bar .brand_logo {
  height: 32px;
  width: 32px;
  border-radius: 50%;
}

.app-bar .left,
.app-bar .institution-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.app-bar .left {
  width: 250px;
}

.app-bar .institution-name {
  max-width: 25vw;
}

/* Drawer Css */
#drawer {
  height: 100%;
  width: 240px;
  z-index: 1032;
  top: 0;
  left: -240px;
  transition: left 0.25s ease;
  background-color: #fff;
}

#drawer.active {
  left: 0;
}

#drawer-void {
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1031;
  background-color: rgba(0, 0, 0, 0.5);
}

#drawer .drawer-user {
  padding: 1.5rem 1rem 1rem;
  text-align: center;
  background-color: #078ece;
  border-bottom: 1px solid #078ece !important;
}

#drawer .drawer-user img {
  height: 83px;
  width: 64px;
  border: 1px solid rgb(228, 227, 227);
}

#drawer .nav-item a {
  text-decoration: none;
}

#drawer .nav-item.active {
  background-color: #e9ecef;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

#drawer .nav-item .item-icon {
  min-width: 32px !important;
}

.fab-container {
  position: fixed;
  bottom: 0;
  right: 0;
}

.truncatable-text,
.breadcrumb-item.truncatable,
.text-truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.truncatable-text {
  width: 250px;
}

.breadcrumb-item.truncatable {
  max-width: 28vw;
}

.text-truncate {
  max-width: 230px;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.elevated-0 {
  box-shadow: 0px 0px 18px -5px rgb(0 0 0 / 14%);
}

.elevated-1 {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
}

.elevated-2 {
  box-shadow: 0px 6px 6px -3px rgb(0 0 0 / 20%),
    0px 10px 14px 1px rgb(0 0 0 / 14%), 0px 4px 18px 3px rgb(0 0 0 / 12%);
}

.elevated-bottom-2 {
  box-shadow: 0px 16px 16px -5px rgb(0 0 0 / 30%);
}

a.hover-text-white:hover {
  color: white !important;
}

a button {
  text-decoration: none;
}

.expected-result-card:hover,
.indicator-card:hover {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
}

.expected-result-card.not-assigned {
  border: 5px solid red !important;
}

.btn-link {
  text-decoration: none !important;
}

.btn-link:hover {
  color: blue !important;
}

.rounded-left-bottom {
  border-radius: 32px !important;
  border-top-left-radius: 0px !important;
}

.rounded-right-bottom {
  border-radius: 32px !important;
  border-bottom-right-radius: 0px !important;
}

.bread-crumb a.active,
nav a.active {
  color: yellow !important;
  text-decoration: underline !important;
}

.summary-card {
  color: #000;
  background-color: #fffaeb;
  text-transform: uppercase;
}

.summary-card .badge-dark {
  background-color: #6e7985 !important;
}

.menu-item {
  display: block;
  max-width: 24vw !important;
}

.role {
  display: block !important;
  max-width: 15vw !important;
}

.summary-card .d-block {
  font-size: 12px;
}

.loader {
  height: 100vh;
  position: relative;
}

.loader img {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.roles span {
  font-size: 14px;
  display: flex;
  align-items: center;
  /* font-weight: bold; */
}

/* =================================== */

.tree-node {
  display: flex;
  align-items: center;
  min-height: 40px;
  max-height: 40px;
}

.tree-node .tree-icon {
  margin-right: 8px;
  border-radius: 50%;
  cursor: pointer;
  background-repeat: no-repeat;
}

.tree-node .tree-icon .open-icon {
  /* background-image: url('../../icons/plus.svg'); */
  width: 12px;
  height: 12px;
  background-size: contain;
}

.tree-node .tree-icon .close-icon {
  /* background-image: url('../../icons/minus.svg'); */
  width: 12px;
  height: 12px;
  background-size: contain;
}

.tree-node .tree-icon .empty-icon {
  /* background-image: url('../../icons/empty.svg'); */
  width: 12px;
  height: 12px;
  background-size: contain;
}

.node-content-wrapper {
  cursor: pointer;
  min-height: 40px;
  max-height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  width: 100%;
  padding: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.2s ease;
}

.node-content-wrapper:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.node-content-wrapper.node-selected {
  border-left: 4px solid red;
}

.node-content-wrapper.titles {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.node-content-wrapper.node-title {
  font-weight: 600;
}

.node-content-wrapper.node-subtitle {
  margin-top: 4px;
  font-size: 10px;
}

.children-length {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 24px;
  height: 16px;
  background-color: red;
  color: white;
  padding: 4px;
  font-size: 12px;
  border-radius: 16px;
  margin-left: 12px;
  font-weight: 600;
}

.tree-wrapper {
  width: 50%;
  padding: 0 0 0 16px;
}

.tree-wrapper.simple {
  overflow: auto;
}

.modules-menu {
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */
}

.modules-menu button,
.modules-menu a {
  /* border: 1px solid #088dce; */
  color: #2a7790;
  font-size: 0.75rem;
  border-radius: 0px !important;
  margin-right: 10px !important;
  font-weight: 500;
  text-align: center;
  transition: all 0.2s ease-in-out;
  text-decoration: none;

  /* border: 1px solid #088dce; */
}

.modules-menu button.active,
.modules-menu a.active {
  color: #fff;
  background-color: #088dce;
}

.modules-menu button.active:hover {
  color: #fffb00 !important;
}

.modules-menu button.active img.invert {
  filter: invert(298%) sepia(152%) saturate(659%) hue-rotate(300deg)
    brightness(94%) contrast(245%);
}

.MuiTreeItem-content {
  /* padding-top: 1px !important;
  padding-bottom: 1px !important; */
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiTreeItem-content .add-padding {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.pagination {
  list-style: none;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
}

.pagination .btn:hover {
  background-color: #add1f7 !important;
  color: #000 !important;
}

.pagination .active {
  background-color: #007bff !important;
}

.pagination .active:hover {
  background-color: #007bff !important;
  color: #fff !important;
}

.elevated {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);
}

.form-control {
  border-width: 1px !important;
  border-color: #17a2b8;
}
.form-control:focus {
  border-color: #2566e8 !important;
  box-shadow: none !important;
  border-width: 2px !important;

  background-color: #fff !important;
  color: rgba(0, 0, 0, 0.87) !important;
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0mv !important;

  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 4px !important;
  /* box-shadow: 0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)!important;; */
}

.searchIcon {
  position: absolute;
  top: 8px;
  right: 24px;
  color: #17a2b8;
}
.loaderIcon {
  position: absolute;
  top: 8px;
  right: 24px;
  color: #17a2b8;
  width: 24px !important;
}
.w-100 {
  width: 100%;
}
.h-50 {
  height: 60px;
}

.exam__dashboard {
  width: 100%;
}
.exam__dashboard .top_cont {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0px 20px; */
  flex-wrap: wrap;
}
.exam__dashboard .top_cont .card_item {
  background-color: #fff;
  display: flex;
  justify-self: start;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  margin: 10px;
  max-width: 280px;
  flex: 1;
  border: 1px solid var(--maincl-lght-l);

  box-shadow: 0px 5px 5px 0px rgba(#2a7790, 0.05);
  -moz-box-shadow: 0px 5px 5px 0px rgba(#2a7790, 0.05);
  -webkit-box-shadow: 0px 5px 5px 0px rgba(#2a7790, 0.05);
}
.exam__dashboard .top_cont .card_item .img_icon {
  width: 80px;
  height: 80px;
  position: relative;
  overflow: hidden;
}
.exam__dashboard .top_cont .card_item .img_icon img {
  position: absolute;
  width: 75%;
  top: 50%;
  left: 0px;
  transform: translateY(-50%);
}

.exam__dashboard .top_cont .details_con h2 {
  font-size: 1rem;
  font-weight: 400;
  color: var(--maincl-lght);
}
.exam__dashboard .top_cont .details_con h3 {
  font-size: 2rem;
  font-weight: 600;
  color: var(--fnheadingcl);
  font-family: "Overpass", sans-serif;
}
thead th {
  border-bottom-width: 1px !important;
}

.tablex thead th {
  vertical-align: bottom;
  border-bottom: thin solid #dee2e6 !important;
  /* text-align: right; */
}
.tablex td {
  width: 130px;
  padding: 0.3rem;
  /* text-align: right; */
}

.align-level-centered {
  text-align: center !important;
}

.align-indexvalue-right {
  text-align: right !important;
}

.tablex td input {
  position: relative;
  padding: 0.3rem;
  /* vertical-align: ; */
  border: 1px solid rgb(7, 142, 206);
  width: 100%;
  text-align: right !important;
}

.tablex td input[type="checkbox"] {
  transform: scale(1.5);
  padding: 0.3rem;
  margin-top: 8px;
  z-index: 0 !important;
}

/* td.span {
  table-layout: fixed;
  width: 500px!important;
} */

.hovered-td {
  background-color: #e9ecef !important;
}
.hovered-td-box {
  padding: 0 !important;
  background-color: #e9ecef !important;
}
.hovered-row {
  background-color: #e9ecef !important;
}

.tablejc td,
.table th {
  padding: 0.75rem;
  /* vertical-align: middle !important; */
}

.span {
  display: inline-block;
  width: 300px !important;
}

.structure-unit-drawer {
  width: 450px;
  /* background-color: hsl(0deg 0% 0% / 4%);
  height: 50vh; */
}

.fixTableHead {
  overflow-y: auto;
  max-height: 110px;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
  z-index: 1 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  padding: 8px 15px;
}
th {
  background: #e9ecef;
}

.table-striped {
  font-size: 11px;
  font-weight: 500;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
}

.table-striped tr:nth-child(even) {
  background-color: #f3f6f8 !important;
}

.table-striped tr:nth-child(odd) {
  background-color: #fff !important;
}

td {
  vertical-align: middle !important;
}

.css-19kzrtu {
  padding: 0px !important;
}

.css-1auycx3-MuiAutocomplete-root
  .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding: 4px !important;
}

.user-contact {
  padding: 10px 0 20px;
  /* border-bottom: 1px solid #a4b0bc; */
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.user-contact-2 {
  flex: 1;
  padding-left: 16px;
}

.user-contact-2 h3 {
  font-size: 0.85rem;
  font-weight: 400;
  color: #088dce;
  line-height: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.user-contact-3 {
  width: 100%;
}
.user-contact-3 h3 {
  font-size: 0.85rem;
  font-weight: 400;
  color: #088dce;
  line-height: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 16px;
}
.user_profile_detail {
  display: block;
  font-size: 0.85rem;
  font-weight: 400;
  color: #344457;
  line-height: 1.8rem;
}

.contact_p {
  font-size: 0.85rem;
  font-weight: 400;
  color: #a4b0bc;
  margin-bottom: 8px;
}
.contact_span {
  font-weight: 600;
  color: #344457;
  font-size: 0.85rem;
}

.card {
  /* border: 1px solid #eff2f5; */
  /* box-shadow: #eff2f5;
  background-color: #ffffff; */
}

.bg-light-success {
  background-color: #eefbec !important;
}
.text-gray-800 {
  color: #28346c !important;
}
.z-index-2 {
  z-index: 2 !important;
}
.fw-bold {
  font-weight: 600 !important;
}
.fs-1 {
  font-size: calc(1.1rem + 0.4vw) !important;
}

.text-gray-600 {
  color: #7e8299 !important;
}
.fw-semibold {
  font-weight: 500 !important;
}
.fs-6 {
  font-size: 1.075rem !important;
}

.mb-7 {
  margin-bottom: 1rem !important;
}

.me-15 {
  margin-right: 3.75rem !important;
}
.h-175px {
  height: 175px !important;
}

.position-relative {
  position: relative !important;
}

@media (min-width: 768px) {
  .me-md-0 {
    margin-right: 0 !important;
  }
}
.me-9 {
  margin-right: 2.25rem !important;
}
.mt-2 {
  margin-top: 0.5rem !important;
}
.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.border-gray-300 {
  border-color: #e4e6ef !important;
}
.min-w-80px {
  min-width: 100px !important;
}
/* .rounded {
  border-radius: 0.85rem !important;
} */

.text-gray-700 {
  color: #596394 !important;
}

.text-gray-400 {
  color: #078ece !important;
}

strong.employee-name {
  display: inline-block;
}

strong.employee-name:first-letter {
  text-transform: uppercase;
}

.border-dashed {
  border-style: solid !important;
  border-color: #078ece;
}
.card-header {
  /* padding: 0.75rem 0.75rem!important;  */
}
.card-title {
  margin-bottom: 0 !important;
}
.notify--info {
  display: -webkit-flex;
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 8px;
  border: 1px solid transparent;
  margin-bottom: 24px;
  box-sizing: border-box;
}

.notify--info::before {
  content: "";
  position: absolute;
  top: -1px;
  left: -1px;
  border: solid 1px;
  width: 4px;
  height: 100%;
  border-radius: 8px 0 0 8px;

  background: rgb(7, 142, 206);
  border-color: rgb(7, 142, 206);
}
.notify__icon-holder {
  padding: 16px 0 0 16px;
}

.notify__content-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1;
  flex: 1;
  padding: 16px 0 0 8px;
  -webkit-flex-flow: row wrap;
  flex-flow: row wrap;
}
.notify__content {
  font-family: DineroSans, sans-serif;
  color: #1c252c;
  font-weight: normal;
  -webkit-flex: 1;
  flex: 1;
  margin: 0 24px 16px 0;
  text-align: left;
  overflow-wrap: break-word;
  word-wrap: break-word;
}
.text--link {
  text-decoration: none;
  color: rgb(7, 142, 206);
  font-weight: bold;
  cursor: pointer;
}

dl,
ol,
ul {
  /* padding-left: 1rem; */
  font-size: 0.875rem;
}
dl li,
ol li,
ul li {
  line-height: 1.8;
}

.bullet-line-list {
  /* margin-left: -1px; */
  position: relative;
  list-style-type: none;
}

.rtl .bullet-line-list {
  padding-left: 0;
  padding-left: initial;
  padding-right: 0px;
}
.bullet-line-list li {
  position: relative;
}
.bullet-line-list:after {
  content: "";
  opacity: 0.1;
  /* border: solid 1px #0063cf; */
  position: absolute;
  /* top: 0;
  bottom: -0.5rem;
  left: 7px; */
}

.services-wrapper {
  margin-top: 0;
}

.services-wrapper li {
  /* margin-left: 10px; */
  /* margin-top: 10px; */
}

.sub-service {
  font-size: 15px;
  line-height: 1;
  letter-spacing: normal;
  color: #39485a;
  cursor: pointer;
  text-decoration: underline;
}

.link-hovered:hover {
  color: #0063cf !important;
}
.card-hovered:hover {
  padding: 3px;
}

/* .sub-service:hover,
.underline:hover {
  text-decoration: underline !important;
  color: #0063cf !important;
  color: #007bff !important;
} */

.text-black {
  color: #000 !important;
}
.ln-h {
  line-height: 1.2 !important;
}

.tr-hovered {
  border-radius: 4px;
  transition: all 0.2s linear !important;
  border-left: 3px solid #f0f2f5;
  border-right: 3px solid #f0f2f5;
  background: #fff !important;
  cursor: pointer;
}

.tr-hovered:hover {
  background: rgba(0, 99, 207, 0.08) !important;
  border-left: 3px solid #376fc8;
  border-right: 3px solid #376fc8;
  box-shadow: inset 0px 0px 0px 1px transparent;
}

.language-card {
  border-left: 4px solid #aaa;
}
.font-weight-bold {
  font-weight: 700 !important;
}

.loaderIcon {
  position: absolute;
  top: 8px;
  right: 24px;
  direction: rtl;
  color: #17a2b8;
  width: 24px !important;
}

.circle {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #088dce;
  color: #fff;
  font-size: 14px;
}
.circle-label {
  color: #000;
  font-size: x-small;
  font-weight: 600;
  text-transform: uppercase;
}

.card-header-active {
  background-color: #effaff;
}
.service-card {
  background-color: #fff !important;
  border-radius: 5px;
  border: 1px solid rgb(7, 142, 206) !important;
}

.service-card > .card-header {
  background-color: #e0edf9;
  color: rgb(7, 142, 206);
  border-bottom: 1px solid rgb(7, 142, 206);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.service-card > .rbm-card-header {
  background-color: #f4f4f4;
  color: rgb(7, 142, 206);
  border-bottom: 1px solid rgb(7, 142, 206);
  font-weight: 500;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  /* border-radius: 5px; */
  /* cursor: pointer; */
}

/* for success color */

.card-header-success-active {
  background-color: #effaff;
}
.service-card-success {
  background-color: #fff !important;
  border-radius: 5px;
  border: 1px solid rgb(20, 164, 77) !important;
}

.service-card-success > .card-header-success {
  background-color: #e0edf9;
  color: rgb(20, 164, 77);
  border-bottom: 1px solid rgb(20, 164, 77);
}
.card-header-success:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.card-header-success:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}
.service-card-success > .rbm-card-header-success {
  background-color: #f7fbff;
  color: rgb(20, 164, 77);
  border-bottom: 1px solid rgb(20, 164, 77);
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
}
.page-loading-void {
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 1031;
  margin-left: -25px;
  margin-right: -15px;
  background-color: rgba(0, 0, 0, 0.08);
}

.rbm-shadow {
  box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%);

  border: 1px solid rgb(17 22 26 / 16%);
}

.hoverable-card {
  transition: all 0.2s linear !important;
}

.hoverable-card:hover {
  /* box-shadow: 0 4px 8px 0 rgb(17 22 26 / 16%), 0 4px 8px 0 rgb(17 22 26 / 8%),
    0 8px 16px 0 rgb(17 22 26 / 4%); */
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
  /* background-color: #fafafa; */
  /* transform: scale(1.005); */
}

.rotated {
  transform: rotate(90deg);
  font-weight: 100 !important;
  color: #87d4d4;
}

hr {
  border-color: rgb(0 0 0 / 30%) !important;
}

.btn-link.active:focus {
  color: #ffff00 !important;
}

.text-blue {
  color: blue !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.rounded-half {
  border-radius: 0.85rem !important;
}

.date-card {
  background-color: rgba(148, 148, 148, 0.068) !important;
  border-radius: 10px;
  border: 1px solid rgba(148, 148, 148, 0.11) !important;
  font-size: 14px !important;
  font-weight: bolder !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-pointer:hover td {
  color: blue !important;
  text-decoration: underline !important;
}

.pre {
  overflow: hidden !important;
  white-space: pre-line !important;
  text-align: justify !important;
  font-size: 17px !important;
  font-family: Times New Roman !important;
  line-height: 24px !important;
}
/* style two factor authentication form */
.otp-field {
  flex-direction: row;
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.otp-field input {
  height: 45px;
  width: 42px;
  border-radius: 6px;
  outline: none;
  font-size: 1.125rem;
  text-align: center;
  border: 1px solid #ddd;
}
.otp-field input:focus {
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.1);
}
.otp-field input::-webkit-inner-spin-button,
.otp-field input::-webkit-outer-spin-button {
  display: none;
}

[contenteditable="false"] {
  color: blue;
}
